import { nanoid } from 'nanoid';
// BOOTSTRAP BREAKPOINTS
export const bootstrapBreakpoints = {
  xs: 428,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

// DEFAULT ANIMATION CONFIG
export const animationConfig = {
  duration: 2,
  y: '100',
  opacity: 0,
  ease: 'ease-in',
};

export const animationConfigSeeAllbtn = {
  duration: 2,
  y: '100',
  opacity: 0,
  delay: 1,
  ease: 'ease-in',
};

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// OFFICES DATA

export const contactData = {
  contactsTitle: 'Contacts',
  contactsText: `If you would like to get in touch with us, please find alternative ways of contacting us below:`,
  contacts: [
    {
      idx: nanoid(),
      icon: 'email',
      contact: 'info@craftablesoftware.com',
      href: 'mailto:',
      analytics: 'Email address',
    },
    {
      idx: nanoid(),
      icon: 'phone',
      contact: '+351 22 606 1266',
      href: 'tel:',
      analytics: 'Phone number',
    },
  ],
  officesTitle: 'Craftable offices',
  offices: [
    {
      idx: nanoid(),
      id: 'porto',
      name: 'Porto (Portugal)',
      address: 'Rua de 5 de Outubro 288, 4100-172, Porto',
      text: 'Located near Casa da Música and Casa da Música tube station, a brisk 3 min walk from the station.',
      image: 'porto.jpg',
      imageBack: 'portoMap.jpg',
      coordsUrl:
        'https://www.google.com/maps?ll=41.160189,-8.631151&z=15&t=m&hl=en&gl=PT&mapclient=embed&cid=1938671367752415322',
      mapSrc: `https://maps.googleapis.com/maps/api/staticmap?center=41.16066,-8.63&size=500x400&scale=2&maptype=terrain&markers=&markers=size:tiny%7Cicon:http://craftablesoftware.com/favicon.ico%7C41.16066,-8.63145&key=AIzaSyDDlv5Zd6GvrwZbR5ORuJ757R3UcJZdZVY`,
    },
    {
      idx: nanoid(),
      id: 'aveiro',
      name: 'Aveiro (Portugal)',
      address: 'Rua de São Martinho 102 Fração H, 3810-183, Aveiro',
      text: 'Located near Forum Aveiro and Aveiro train station, a 15 min walk from the station, or a 1 min walk from the other side of the channel if you decide to swim.',
      image: 'aveiro.jpg',
      imageBack: 'aveiroMap.jpg',
      coordsUrl:
        'https://www.google.com/maps?ll=40.6403359,-8.649843&z=15&t=m&hl=en&gl=PT&mapclient=embed&cid=1938671367752415322',
      mapSrc:
        'https://maps.googleapis.com/maps/api/staticmap?size=500x400&scale=2&maptype=roadmap&markers=size:tiny%7Cicon:http://craftablesoftware.com/favicon.ico%7C40.6403359,-8.6499&key=AIzaSyDDlv5Zd6GvrwZbR5ORuJ757R3UcJZdZVY',
    },
  ],
};

// MENU DATA
export const headerData = {
  menu: [
    {
      idx: nanoid(),
      id: 'heroabout',
      responsive_id: 'hero',
      name: 'About us',
      active: true,
    },
    {
      idx: nanoid(),
      id: 'services',
      responsive_id: 'services',
      name: 'Services',
      active: true,
    },
    {
      idx: nanoid(),
      id: 'blog',
      responsive_id: 'bloganchor',
      name: 'Blog',
      active: true,
    },
    {
      idx: nanoid(),
      id: 'careers',
      responsive_id: 'careerssanchor',
      name: 'Careers',
      active: true,
    },
    {
      idx: nanoid(),
      id: 'contacts',
      responsive_id: 'contactsanchor',
      name: 'Contacts',
      active: true,
    },
    {
      idx: nanoid(),
      id: 'values',
    },
  ],

  startProject: [
    {
      idx: nanoid(),
      id: 'startproject',
      name: 'Start a project',
      active: true,
    },
  ],
};

// INSTITUTIONAL DATA
export const institutionalData = [
  {
    idx: nanoid(),
    id: 'about',
    title: 'We Care',
    text: `
			We commit to the best outcome. This requires 
			attention to detail, constant communication with our partners and above all <b>pride in what we do.</b> 
			Your success is our success.
		`,
  },
  {
    idx: nanoid(),
    id: 'projects',
    title: 'Teamplay',
    text: `
			Teamplay is our main engine. 
			We believe that, as with sports, <b>teamwork is pivotal.</b> 
			Great results can only be achieved when a group of people works as one.
		`,
  },
  {
    idx: nanoid(),
    id: 'contact',
    title: 'Feel the breeze',
    text: `
    We try not to take ourselves too seriously but rather <b>enjoy the ride.</b>
			That’s why we promote a relaxed environment and we encourage interaction and knowledge transfer between team members.
		`,
  },
];
